import React, { Suspense, lazy } from 'react';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import { motion } from 'framer-motion'; // Replace react-scroll
import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import Appbar from './components/Appbar';
import Images from './components/Images';

// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
const Services = lazy(() => import('./components/Services'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const Contact = lazy(() => import('./components/Contact'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));

function App() {
  return (
    // Added basename="/" for consistent routing in both root and subfolders
    <BrowserRouter basename="/">
      <ScrollToTop />
      <div className="App">
        {/* Appbar */}
        <header style={{ marginBottom: '60px' }}>
          <Appbar />
        </header>

        {/* Main Content */}
        <main>
          <Suspense fallback={<div className="text-center">Loading...</div>}>
            <Routes>
              {/* Root Route */}
              <Route
                path="/"
                element={
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                    <Hero />
                  </motion.div>
                }
              />

              {/* Services Routes */}
              <Route path="/services" element={<Services />} />
              <Route path="/services/:serviceId" element={<Services />} /> {/* Handles dynamic service routes */}

              {/* Other Routes */}
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
          </Suspense>

          {/* Contact Section */}
          <motion.div id="contact" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Contact />
          </motion.div>
        </main>
        {/* Footer */}
        <footer className="footer MyGradient">
          <div className="footer-nav">
            <ul className="nav-list">
              <li>
                <Link to="/privacypolicy" className="nav-link">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <p className="footer-text">&copy; Techdriven LLC - All Rights Reserved</p>
        </footer>

      </div>
    </BrowserRouter>
  );
}

export default App;
