import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Images from './Images';

const pages = ['About Us', 'Contact Us'];
const services = [
  { title: 'Onsite IT Support', path: 'onsite-it-support' },
  { title: 'Remote IT Support', path: 'remote-it-support' },
  { title: 'IT Consulting', path: 'it-consulting' },
];

function ResponsiveAppBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElServices, setAnchorElServices] = useState(null);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuOpen = (setAnchor) => (event) => setAnchor(event.currentTarget);
  const handleMenuClose = (setAnchor) => () => setAnchor(null);

  const toggleServicesMenu = (event) => {
    setAnchorElServices(event.currentTarget);
    setServicesMenuOpen(!servicesMenuOpen);
  };

  return (
    <AppBar
      position="fixed"
      className="TransparentAppBar"
      elevation={0}
    >
      <Toolbar>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '12px' }}>
          <Link to="/">
            <Images
              name="techdriven"
              alt="TechDriven Logo"
              style={{ width: '150px', height: '40px', objectFit: 'contain' }}
            />
          </Link>
        </Box>
        {/* Desktop Navigation */}
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 4,
              flexGrow: 1,
            }}
          >
            {/* Services Dropdown */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={toggleServicesMenu}
            >
              <span
                style={{
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '400',
                }}
              >
                Services
              </span>
              <ExpandMoreIcon
                style={{ color: 'white', marginLeft: '4px' }}
              />
            </div>

            <Menu
              anchorEl={anchorElServices}
              open={servicesMenuOpen}
              onClose={() => setServicesMenuOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{
                '& .MuiPaper-root': {
                  background: '#007F52',
                  color: 'white',
                },
              }}
            >
              {services.map((service) => (
                <MenuItem key={service.title} onClick={() => setServicesMenuOpen(false)}>
                  <Link
                    to={`/services/${service.path}`}
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    {service.title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>

            {/* Static Pages */}
            {pages.map((page) => (
              <Link
                key={page}
                to={`/${page.toLowerCase().replace(/\s/g, '-')}`}
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '400',
                }}
              >
                {page}
              </Link>
            ))}
          </Box>
        )}

        {/* Mobile Navigation */}
        {isMobile && (
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label="menu"
              onClick={handleMenuOpen(setAnchorElNav)}
              style={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleMenuClose(setAnchorElNav)}
              PaperProps={{
                style: {
                  background: '#007F52',
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif',
                },
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={toggleServicesMenu}>
                Services <ExpandMoreIcon sx={{ marginLeft: '8px' }} />
              </MenuItem>
              {servicesMenuOpen &&
                services.map((service) => (
                  <MenuItem key={service.title} onClick={handleMenuClose(setAnchorElNav)}>
                    <Link
                      to={`/services/${service.path}`}
                      style={{
                        color: 'white',
                        textDecoration: 'none',
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {service.title}
                    </Link>
                  </MenuItem>
                ))}
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleMenuClose(setAnchorElNav)}>
                  <Link
                    to={`/${page.toLowerCase().replace(/\s/g, '-')}`}
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    {page}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;
