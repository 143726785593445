// Images.js

import React from 'react';

const Images = ({ name, alt, className = "", style = {} }) => {
  const avifPath = `/images/${name}.avif`;
  const pngPath = `/images/${name}.png`;

  return (
    <img
      src={avifPath}
      alt={alt || "Image"}
      loading="lazy"
      // No more forced contact-image class:
      className={className} 
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = pngPath; // fallback to PNG
      }}
      style={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover', // Default style
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 127, 82, 0.5)', // Default shadow
        ...style, // Merge any inline override
      }}
    />
  );
};

export default Images;

